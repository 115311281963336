
import { Options, Vue } from "vue-class-component";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";

@Options({
  components: {
    LeftStepBar,
  },
})
export default class ThankYou extends Vue {}
