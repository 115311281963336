<template>
  <div class="md:flex justify-between">
    <div class="md:w-8/12 w-full relative background-cube inner-page pb-20">
      <div class="md:w-8/12">
        <SectionTitle class="w-full" :text="$t('mainTitle')" />
        <LeftStepBar
          :step="4"
          :title="$t('questionnaire_evaluation.title')"
          :perex="$t('questionnaire_evaluation.perex')"
        />
      </div>
    </div>
    <div class="md:w-4/12 md:pt-36 font-serif text-sm">
      <p v-html="$t('investment_strategy.block')" />

      <h4 class="my-10 font-sans">
        {{ investor }}
      </h4>

      <p v-html="$t('investment_strategy.block2')" />

      <router-link
        to="/steps/investment-strategy"
        class="mt-16 mb-24 w-full btn secondary flex-1 font-sans"
        v-html="$t('questionnaire_evaluation.select_strategies')"
      />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import { mapGetters } from "vuex";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";

@Options({
  data() {
    return {
      investor: "",
    };
  },
  components: {
    SectionTitle,
    LeftStepBar,
  },
  created() {
    this.findNameById(this.riskProfile);
  },
  beforeMount() {
    this.$store.commit("onboarding/checkProfile");
  },
  mounted() {
    // this.$store.dispatch("questionnaire/getRiskProfiles");
    console.log(this.profile);
  },
  computed: {
    ...mapGetters({
      profile: "onboarding/myProfile",
      riskProfile: "questionnaire/getRiskProfile",
      riskProfiles: "questionnaire/getRiskProfiles",
      investorName: "questionnaire/getInvestorName",
    }),
  },
  methods: {
    findNameById(id) {
      this.riskProfiles.forEach((o) => {
        if (o.id === id) {
          if (typeof localStorage !== 'undefined' && localStorage.getItem('wem_locale') === 'sk') {
            this.$store.commit("questionnaire/setInvestorName", o.name);
            return (this.investor = o.name);
          } else {
            this.$store.commit("questionnaire/setInvestorName", o.name_en);
            return (this.investor = o.name_en);
          }
        }
      });
    },
  },
})
export default class QuestionnaireEvaluation extends Vue {}
</script>
