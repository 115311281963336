<template>
  <div class="md:flex justify-between">
    <div class="md:w-8/12 w-full relative background-cube inner-page pb-20">
      <div class="md:w-8/12">
        <SectionTitle
          class="w-full"
          :text="$t('questionnaire.section-1.updateTitle')"
        />
        <LeftStepBar
          :title="$t('questionnaire_evaluation.title')"
          :perex="$t('questionnaire_evaluation.perex')"
        />
      </div>
    </div>
    <div class="md:w-4/12 md:pt-36 font-serif text-sm">
      <p v-html="$t('investment_strategy.block')" />

      <h4 class="my-10 font-sans">
        {{ investor || profileName }}
      </h4>

      <p v-html="$t('investment_strategy.updateBlock2')" />
      <div class="flex justify-end space-x-5 items-end mb-0">
        <FormInput
          class="mt-10"
          style="width: 170px"
          id="signcode"
          :label="$t('form.codeField')"
          type="text"
          v-model:model="code"
        />
        <button v-if="!showSendEmailButton" @click="sendFormCode" class="btn primary normal-case" style="width: 65%">
          {{ $t("form.send_sms_code") }}
        </button>

<!--        <div v-if="timer != 0 " class="mt-10">-->
<!--          Timer: {{ timer }} seconds-->
<!--        </div>-->

        <button
          v-if="showSendEmailButton && timer > 0"
          @click="sendEmailCode"
          :class="{ 'disabled': timer > 0 }"
          class="btn primary normal-case"
          style="width: 65%"
        >
          {{ $t("form.wait_for_code") }}
        </button>
        <button
          v-if="showSendEmailButton && timer == 0"
          @click="sendEmailCode"
          class="btn primary normal-case"
          style="width: 65%"
        >
          {{ $t("form.send_email_code") }}
        </button>
      </div>
      <div v-if="showSendEmailButton && timer == 0" class="mt-10">
       {{ $t("form.try_email")}}
      </div>

      <button
        @click="signForm"
        class="mt-16 mb-24 w-full btn secondary flex-1 font-sans"
      >
        {{ $t("sign") }}
      </button>
      <!--      <router-link-->
      <!--        to="/steps/investment-strategy"-->
      <!--        class="mt-16 mb-24 w-full btn secondary flex-1 font-sans"-->
      <!--        v-html="$t('questionnaire_evaluation.select_strategies')"-->
      <!--      />-->
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import { mapGetters } from "vuex";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";
import FormInput from "@/shared/components/FormInput.vue";
import { getLocale } from "@/i18n";

@Options({
  data() {
    return {
      investor: "",
      code: "",
      profileName: "",
      timer: 40, // Initialize the timer
      showSendEmailButton: false, // Control the button's visibility
    };
  },
  components: {
    FormInput,
    SectionTitle,
    LeftStepBar
  },
  created() {
    this.getTokenFromUrl();
    this.findNameById(this.riskProfile);
    this.profileName = this.riskProfile;
  },
  mounted() {
    this.$store.dispatch("questionnaire/getRiskProfiles");
    var params = new URLSearchParams();
    params.append("scope", "onboarding");
    params.append("user", "onboarding");
    params.append("pass", "6N9Xebc0i!Nn01%*G2d5");

    this.$store.dispatch("onboarding/setAuthToken", { params });
  },
  computed: {
    ...mapGetters({
      profile: "onboarding/myProfile",
      riskProfile: "questionnaire/getRiskProfile",
      riskProfiles: "questionnaire/getRiskProfiles",
      investorName: "questionnaire/getInvestorName"
    })
  },
  methods: {
    getTokenFromUrl() {
      const token = window.location.hash.split("/")[3];
      this.$store.commit("onboarding/setToken", token);
    },

    findNameById(id) {
      console.log("Investor", this.riskProfiles);
      this.riskProfiles.forEach((o) => {
        if (o.id === id) {
          this.$store.commit("questionnaire/setInvestorName", o.name);
          return (this.investor = o.name);
        }
      });
    },

    sendFormCode() {
      const params = new URLSearchParams();
      params.append("signature_token", localStorage.getItem("signatureToken"));
      params.append("lang", getLocale());
      this.$store.dispatch("onboarding/sendFormSignCode", params);

      // Start a 60-second timer
      this.timer = 40;
      this.showSendEmailButton = true;
      // Decrease the timer every second
      const intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          // When the timer reaches 0, clear the interval and show the "Send Email Code" button
          clearInterval(intervalId);

        }
      }, 1000); // Update the timer every second (1000 ms)
    },

    sendEmailCode() {
      const params = new URLSearchParams();
      params.append("signature_token", localStorage.getItem("signatureToken"));
      params.append("lang", getLocale());

      this.$store.dispatch("onboarding/sendEmailSignCode", params);
    },

    signForm() {
      const params = new URLSearchParams();
      params.append("signature_token", localStorage.getItem("signatureToken"));
      params.append("sms_code", this.code);
      params.append("lang", getLocale());

      this.$store.dispatch("onboarding/signForm", params);
    }
  }
})
export default class QuestionnaireEvaluation extends Vue {
}
</script>

<style scoped>
/* Define the styles for the disabled button */
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}
</style>
